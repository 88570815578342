import { useEffect, useContext, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'

import {
  Container,
  CalendarIcon,
  ClockIcon,
  DataContainer,
  DataHead,
  DataTable,
  DataTitle,
  Input,
  InputContainer,
  InputLabel,
  OuterRightTableHeader,
  OuterLeftTableHeader,
  PageContainer,
  Title,
  Tooltip,
  TreatmentType,
} from '../components/Shared'
import Header from '../components/Header'
import { ContestModal, ConfirmModal, ExpirationModal } from '../components/modals'
import { ContestButton, UncontestButton } from '../components/buttons'
import EmptyTable from '../components/EmptyTable'
import ReminderNote from '../components/ReminderNote'

import globalContext from '../global-context'

const BookingPage = () => {
  const { id } = useParams()
  const { fetchBookings, bookings, setBookings, contestBooking, authErrorMessage, isAuthError } =
    useContext(globalContext)
  const [contest, setContest] = useState(null)
  const [bookingSearch, setBookingSearch] = useState('')
  const [openedRow, setOpenedRow] = useState(null)
  const [ascending, setAscending] = useState(true)

  const month = format(new Date(), 'MMMM', { locale: sv })
  const year = format(new Date(), 'y')

  const onContest = async ({ reason, id: entityId = null }) => {
    const contestId = entityId || contest.id

    await contestBooking({
      id: contestId,
      reason,
      contest: true,
    })

    setContest(null)
    fetchBookings(id)
  }

  const unContest = async ({ id: entityId }) => {
    await contestBooking({
      id: entityId,
      contest: false,
    })
  }

  const filterBookings = useCallback(
    bookings => {
      const searchBookings = bookings
        .filter(
          booking =>
            booking.patient.name.toLowerCase().includes(bookingSearch) ||
            booking.patient.ssn.toLowerCase().includes(bookingSearch) ||
            booking.patient.phone.toLowerCase().includes(bookingSearch) ||
            booking.patient.email.toLowerCase().includes(bookingSearch)
        )
        .map(booking => ({ ...booking, fromSearch: true }))

      const normalBookings = bookings
        .filter(
          booking =>
            !booking.patient.name.toLowerCase().includes(bookingSearch) &&
            !booking.patient.ssn.toLowerCase().includes(bookingSearch) &&
            !booking.patient.phone.toLowerCase().includes(bookingSearch) &&
            !booking.patient.email.toLowerCase().includes(bookingSearch)
        )
        .map(booking => ({ ...booking, fromSearch: false }))

      let allBookings = [...searchBookings, ...normalBookings]
      setBookings(allBookings)

      return allBookings
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bookingSearch]
  )

  const sortBookingColumns = e => {
    let item = e.target.id

    switch (item) {
      case 'date':
        sortBookingItems('date')
        break
      case 'name':
        sortBookingItems('name')
        break
      case 'ssn':
        sortBookingItems('ssn')
        break
      case 'phone':
        sortBookingItems('phone')
        break
      case 'email':
        sortBookingItems('email')
        break
      case 'treatment_name':
        sortBookingItems('treatment_name')
        break
      default:
        break
    }

    function sortBookingItems(item) {
      let keyItem
      bookings.map(booking =>
        Object.keys(booking).find(key => (key === item ? (keyItem = key) : null))
      )
      if (!ascending) {
        setBookings([
          ...bookings.sort((a, b) => {
            let itemA, itemB
            // return value that is found inside a booking object (not nested)
            if (keyItem) {
              itemA = Object.entries(a).find(key => key[0] === keyItem)
              itemB = Object.entries(b).find(key => key[0] === keyItem)
              return itemA[1] > itemB[1] ? 1 : -1
            } else {
              // return value inside patient object (nested)
              itemA = Object.entries(a.patient).find(key => key[0] === item)
              itemB = Object.entries(b.patient).find(key => key[0] === item)
              return itemA[1].toLowerCase() > itemB[1].toLowerCase() ? 1 : -1
            }
          }),
        ])
        setAscending(true)
      } else {
        setBookings([
          ...bookings.sort((a, b) => {
            let itemA, itemB
            if (keyItem) {
              itemA = Object.entries(a).find(key => key[0] === keyItem)
              itemB = Object.entries(b).find(key => key[0] === keyItem)
              return itemA[1] < itemB[1] ? 1 : -1
            } else {
              itemA = Object.entries(a.patient).find(key => key[0] === item)
              itemB = Object.entries(b.patient).find(key => key[0] === item)
              return itemA[1].toLowerCase() < itemB[1].toLowerCase() ? 1 : -1
            }
          }),
        ])
        setAscending(false)
      }
    }
  }

  const openModal = e => setOpenedRow(parseInt(e.currentTarget.id))

  const onClearInput = () => bookingSearch.length !== 0 && setBookingSearch('')

  useEffect(() => {
    fetchBookings(id)
    return () => {
      setBookings(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (bookingSearch !== '') {
      filterBookings(bookings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingSearch, filterBookings])

  if (!bookings)
    return (
      <>
        <Header />
        <PageContainer>
          <Container>
            <Title>Laddar bokningar...</Title>
            {isAuthError ? <ExpirationModal authError={authErrorMessage} /> : null}
          </Container>
        </PageContainer>
      </>
    )

  return (
    <>
      <ContestModal
        open={contest !== null}
        unContest={unContest}
        onClose={() => setContest(null)}
        onSubmit={onContest}
      />
      {isAuthError ? <ExpirationModal authError={authErrorMessage} /> : null}
      <Header />
      <PageContainer>
        <Container>
          <Title>
            Bokningar för {month} {year}
          </Title>
          <ReminderNote />
          <DataContainer onSubmit={e => e.preventDefault()}>
            <>
              <DataHead>
                <DataTitle>Bokningar via Tandläkare.se</DataTitle>
                <InputContainer>
                  <Input
                    placeholder={'Sök...'}
                    value={bookingSearch}
                    onChange={e => setBookingSearch(e.target.value.toLocaleLowerCase())}
                  />
                  <InputLabel onClick={onClearInput}>x</InputLabel>
                </InputContainer>
              </DataHead>

              <DataTable>
                <thead>
                  <tr onClick={sortBookingColumns}>
                    <OuterLeftTableHeader id="date">Datum</OuterLeftTableHeader>
                    <th id="name">Namn</th>
                    <th id="ssn">Personnummer</th>
                    <th id="phone">Telefon</th>
                    <th id="email">Email</th>
                    <th id="treatment_name">Behandlingstyp</th>
                    <OuterRightTableHeader></OuterRightTableHeader>
                  </tr>
                </thead>
                {bookings && bookings.length > 0 ? (
                  <tbody>
                    {bookings.map(booking => (
                      <tr
                        key={booking.id}
                        className={`${booking.contest?.contested ? 'contested' : ''} ${
                          booking.fromSearch && bookingSearch !== '' ? 'from-search' : ''
                        }`}
                      >
                        <td>{format(new Date(booking.date), 'yyyy-MM-dd')}</td>
                        <td>{booking.patient.name}</td>
                        <td>{booking.patient.ssn}</td>
                        <td>
                          {booking.patient.phone.slice(0, 2).includes('00')
                            ? '+' + booking.patient.phone.substring(2)
                            : booking.patient.phone}
                        </td>
                        <td>{booking.patient.email}</td>
                        <td>
                          <TreatmentType>
                            {booking.type?.toLowerCase() === 'sistaminuten' ? (
                              <ClockIcon data-tip data-for={`booking-type-${booking.id}`} />
                            ) : (
                              <CalendarIcon data-tip data-for={`booking-type-${booking.id}`} />
                            )}
                          </TreatmentType>
                          <Tooltip
                            id={`booking-type-${booking.id}`}
                            place="top"
                            effect="solid"
                            type="dark"
                          >
                            {booking.type}
                          </Tooltip>{' '}
                          {booking.treatment_name}
                        </td>

                        <td>
                          {booking.contest?.contested ? (
                            <>
                              <UncontestButton onClick={e => openModal(e)} id={booking.id}>
                                <span>Ångra</span>
                              </UncontestButton>
                              {booking.id === parseInt(openedRow) && (
                                <ConfirmModal
                                  type="booking"
                                  setOpenedRow={setOpenedRow}
                                  setBookings={setBookings}
                                  fetchBookings={fetchBookings}
                                  itemId={booking.id}
                                  paramId={id}
                                  page={'booking'}
                                />
                              )}
                            </>
                          ) : (
                            <ContestButton
                              onClick={() =>
                                setContest({
                                  id: booking.id,
                                  type: 'booking',
                                })
                              }
                            >
                              Neka
                            </ContestButton>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <EmptyTable
                    text={'Just nu har du inga bokningar som behöver godkännas'}
                    colSpan={7}
                  />
                )}
              </DataTable>
            </>
          </DataContainer>
        </Container>
      </PageContainer>
    </>
  )
}

export default BookingPage
