import styled from 'styled-components'

const ReminderNote = () => {
  return (
    <Container>
      <p>
        Är du osäker på vilka premisser patienter skall nekas? Vår överenskommelse är att nya
        patienter som kom på sitt besök skall accepteras.
      </p>
      <p>Skäl till att neka förmedlade onlinebokningar eller samtal:</p>
      <ul>
        <li>Patienten kom inte på besöket. Välj då Neka -{'>'} Dök inte upp</li>
        <li>
          Patienten har, inom 24 månader tidigare, varit hos er. Välj då Neka -{'>'} Befintlig
          patient
        </li>
        <li>
          Patienten har ombokat sitt besök till nästa månad, eller efter det. Välj då Neka -{'>'}{' '}
          Ombokad till senare{' '}
        </li>
      </ul>
      <p>
        För att följa upp förmedlade samtal använder ni telefonnumret i ert journalsystem och ser om
        det tillhör en ny patient.
      </p>
      <p>Hör gärna av er till oss om ni är osäkra kring något, så hjälper vi till.</p>
    </Container>
  )
}
export default ReminderNote

const Container = styled.div`
  padding: 10px 20px;
  background: #fdf7f0;
  border-radius: 5px;
  line-height: 1.4;
`
